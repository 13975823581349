
export default
  layout: 'promo'
  metaInfo:
    title: 'HoneyMoney Story' #70 characters
    meta: [
      { name: 'description', content: 'How HoneyMoney started' }
    ]
  components:
    hm_slideshow: require('@/components/hm_slideshow.vue').default
  mounted: ->
    $('#intro_text').show()
    setTimeout (->
      $('#intro_text').hide()
      $('#honey_money_text').show().addClass('animated')
    ), 5000

  # components:
  #   sign_up_hero: require('promo/en/layouts/sign_up_hero.vue').default
  #   hm_slideshow: require('@/components/hm_slideshow.vue').default
  #   io_form:  require('@/components/io_form.vue').default
  # data: ->
  #   menu_visible: false
